@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
        background-color: #0a192f;
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}